import axios from 'axios';
let _requestCount = 1;
const getRequestId = () => {
  let requestId = _requestCount;
  _requestCount++;
  return requestId;
};

export default {
  namespaced: true,
  state: {
    country: {
      city: 'some city',
      country: 'some country',
      district: '7',
      region: '1'
    }
  },
  getters: {
    
  },
  mutations: {
    setCountry(state, c) {
      state.country = c;
    }
  },
  actions: {
    async addCountry({ commit }, pl) {
      commit('setCountry', pl);
    }
  },
};
