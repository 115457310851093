function prepareResults(data) {
  if (data.results) {
    return data.results;
  } else {
    if (data.length) {
      return data;
    } else {
      return false;
    }
  }
}

import { USER_INVITE_STATUSES } from '@enum/variable';

export default (SendRequest) => {

  const request = {};

  request.sendSMS = (formData) => {
    return SendRequest('POST', `/v1/invite/users/`, formData, data => {
      return data || { error: true };
    });
  };

  request.getRegisteredUserList = (params) => {
    return SendRequest('GET', `/v1/users/list-invite/`, params, data => {
      return data || { error: true };
    });
  };

  request.sendRegisteredUser = (params) => {
    return SendRequest('POST', `/v1/invite/users/registered/`, params, data => {
      return data || { error: true };
    });
  };


  request.getEmployeeList = (params) => {
    return SendRequest('GET', `/v1/invite/users/`, params, data => {
      return data || { error: true };
    });
  };

  request.removeEmployee = (inviteId) => {
    return SendRequest('PATCH', `/v1/invite/users/${inviteId}/`, { status: USER_INVITE_STATUSES.REJECTED_COMPANY.KEY }, data => {
      return data || { error: true };
    });
  };

  request.getExecutors = () => {
    return SendRequest('GET', `/v1/invite/work/`, {}, data => {
      const results = data.results.map(result => {
        const info = {
          fullName: `${result?.to_user?.first_name} ${result?.to_user?.last_name}`,
          role: result?.to_user?.company_service?.responsibility?.name,
          photo: result?.to_user?.photo,
        };

        return {
          ...result,
          info,
        };
      });
      const result = {
        ...data,
        results,
      };

      return result || { error: true };
    });
  };

  window.dayjs = DateTime;

  request.getCompaniesRequests = (params) => {
    return SendRequest('GET', `/v1/companies/users/employment/`, params, data => {
      return data || { error: true };
    });
  };

  request.getInviteList = (params) => {
    return SendRequest('GET', `/v1/users/invite/`, params, data => {
      return data || { error: true };
    });
  };

  request.setInviteStatus = (userId, inviteId, status) => {
    return SendRequest('PATCH', `/v1/users/${userId}/invite/${inviteId}/`, { status }, data => {
      return data || { error: true };
    });
  };

  request.unregisteredUserRejectInvite = (inviteId) => {
    return SendRequest('PATCH', `/v1/invite/reject/${inviteId}/`, { status: USER_INVITE_STATUSES.REJECTED.KEY }, data => {
      return data || { error: true };
    });
  };

  request.unregisteredUserAcceptInvite = (inviteId) => {
    return SendRequest('PATCH', `/v1/invite/accepted/${inviteId}/`, { status: USER_INVITE_STATUSES.JOINED.KEY }, data => {
      return data || { error: true };
    });
  };

  request.unregisteredUserGetInviteInfo = (inviteId) => {
    return SendRequest('GET', `/v1/invite/reject/${inviteId}/`, null , data => {
      return data || { error: true };
    });
  };

  request.getInfo = (userId, inviteId) => {
    return SendRequest('GET', `/v1/users/${userId}/invite/${inviteId}/`, null, data => {
      return data || { error: true };
    });
  };

  request.updateEmployeeService = (userId, serviceId, params) => {
    return SendRequest('PATCH', `/v1/users/${userId}/services_company/${serviceId}/`, params, data => {
      return data || { error: true };
    });
  };

  request.leaveCompany = () => {
    return SendRequest('POST', `/v1/invite/out/`, { status: USER_INVITE_STATUSES.REJECTED.KEY }, data => {
      return data || {error: true};
    });
  };


  return request;
};
