import Vue from 'vue';
import { mask } from 'vue-the-mask';

Vue.directive('mask', function(el, binding) {

  if(!binding.value)
  {
    return;
  }

  mask(el, binding);
});
