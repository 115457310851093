
export default (SendRequest) => {

  const request = {};

  request.authorize = (user) => {
    return SendRequest('POST', '/v1/otp/auth/token/', user, (data) => {
      // if(data.jwt) {
      //   return {token: data.jwt};
      // }
      // TODO: вызвать исключение
      return data.token ? data.token : false;
    });
  };

  request.confirmPhone = mobile => {
    // return SendRequest('POST', '/v1/otp/auth/token/', {
    //   email: "user@example.com",
    //   mobile: "+79619622553",
    //   token: "5665"
    // }, data => {
    //   console.log(data);
    // });
    return SendRequest('POST', '/v1/otp/auth/mobile/', { mobile }, data => {
      //toDo
      if (data.detail === 'We texted you a login code.') {
        return { success: true };
      } else {
        return { success: false, err: data.detail };
        // return { success: true };
      }
    });
  };

  request.connectFirebase = (registration_id) => {
    return SendRequest('POST', '/v1/fcm/devices/', {
      registration_id,
      type: 'web'
    }, data => data);
  };

  return request;
};
