import $store from '@store';

export const isCompany = () => $store.getters['user/company'];
export const isHelper = () => $store.state.user.user?.helper_mode;
export const sidebar = () => {

  if (isHelper()) {

    if (isCompany()) {
      return import('@component/Part/SidePanelCompany');
    }

    return import('@component/Part/SidePanelProvide');
  }

  return import('@component/Part/SidePanelNeed');
};

export const template = (template) => {

  if (isHelper()) {

    if (isCompany()) {
      return import(`@page/LkAssistanceProvideCompany/${template}`);
    }

    return import(`@page/LkAssistanceProvidePerson/${template}`);
  }

  return import(`@page/LkAssistanceNeed/${template}`);
};
