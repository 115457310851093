export default (SendRequest) => {
  const request = {};

  request.create = (userId, formData) => {

    return SendRequest('POST', `/v1/users/${userId}/timetable/`, formData, response => {
      return response;
    });
  };

  request.update = (userId, timetableId, formData) => {
    return SendRequest('PATCH', `/v1/users/${userId}/timetable/${timetableId}/`, formData, data => {
      return data;
    });
  };

  request.getList = (userId) => {
    return SendRequest('GET', `/v1/users/${userId}/timetable/`, null, data => {
      return data.results;
    });
  };

  request.getDays = (userId) => {
    return SendRequest('GET', `/v1/users/${userId}/timetable/get_days_of_week/`, null, data => {
      return data;
    });
  };

  return request;
};
