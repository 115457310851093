function prepareResults(data) {
  if (data.results) {
    return data.results;
  } else {
    if (data.length) {
      return data;
    } else {
      return false;
    }
  }
}

export default SendRequest => {
  const request = {};

  request.getRequests = params => {
    return SendRequest('GET', `/v1/work/requests/`, params, data => {
      data.results = data.results.map(el => ({
        ...el,
        address: {
          ...el.address,
          mapsCoordinates: [el.address?.coordinates?.latitude, el.address?.coordinates?.longitude]
        },
      }));
      return prepareResults(data) || { error: true };
    });
  };

  request.getRequest = (id, params) => {
    return SendRequest('GET', `/v1/work/requests/${id}/`, params, data => {
      return data || { error: true };
    });
  };

  return request;
};
