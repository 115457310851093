<template>
  <!--    // :class="getGridClass"-->
  <div id="app" data-app :class="{ 'theme--red' : $route.meta.theme === 'red'}">
    <component :is="currentLayout" />
    <Modal></Modal>
  </div>
</template>

<script>
// import grid from '@/core/layout/mixin/grid';
import layoutTemplate from '@/core/layout/mixin/vue-extend-layout';
// import LoadingBar from '@/component/Widget/LoadingBar';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'layout-default',
  metaInfo() {
    return {
      script: [
        {
          src: `https://maps.googleapis.com/maps/api/js?key=AIzaSyAtoC1pyMlC0JWJvXkxhMtchXAqBK4bFjU&libraries=places`,
          async: true,
          defer: true,
        },
      ],
    };
  },
  watch: {
    async isAuth(v) {
      if (!v || Notification.permission !== 'granted') return;

      await this.$getFirebaseMessagingToken();

    }
  },
  mixins: [layoutTemplate],
  computed: {
    ...mapState({
      user: state => state.user.user,
      isAuth: state => state.auth.isAuth
    })
  },
  methods: {
    ...mapActions({
      getUser: 'user/getUser',
      addEventListeners: 'notification/addEventListeners'
    }),
  }
};
</script>

<style lang="scss" scoped>
.loader {
  position: fixed;
}
</style>
