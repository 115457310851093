export default (SendRequest) => {

  const request = {};

  request.create = (userId, relative) => {
    const formData = new FormData();

    Object.entries(relative).forEach(([k, v]) => {
      if (v) {
        formData.append(k, v);
      }
    });

    return SendRequest('POST', `/v1/users/${userId}/family/`, formData, data => {
      return data || { error: true };
    });
  };

  request.update = (userId, familyId, formData) => {
    return SendRequest('PATCH', `/v1/users/${userId}/family/${familyId}/`, formData, data => {
      return data || { error: true };
    });
  };

  request.getFamilyAddress = (userId, familyId) => {
    return SendRequest('GET', `/v1/users/${userId}/family/${familyId}/address/`, null, (data) => data || ({ error: true }));
  };

  request.createFamilyAddress = (userId, familyId, formData) => {
    return SendRequest('POST', `/v1/users/${userId}/family/${familyId}/address/`, formData, (data) => data || ({ error: true }));
  };

  request.updateFamilyAddress = (userId, familyId, addressId, formData) => {
    return SendRequest('PATCH', `/v1/users/${userId}/family/${familyId}/address/${addressId}/`, formData, (data) => data || ({ error: true }));
  };

  return request;

};
