import firebase from 'firebase/app';
import 'firebase/firebase-messaging';
import $store from '@store';
import 'firebase/firebase-analytics';

const firebaseConfig = {
  apiKey: "AIzaSyAUVNGl_z-8qGONHplopSwKVD4m8OauSDo",
  authDomain: "wecare-a7f06.firebaseapp.com",
  projectId: "wecare-a7f06",
  storageBucket: "wecare-a7f06.appspot.com",
  messagingSenderId: "657601829161",
  appId: "1:657601829161:web:a217b85cc59271cd9e3f38",
  measurementId: "G-26C6KJ7NB7"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

// const vapidKey = 'BN2pbQaxwI-V0Fw_ZK-b5qHmlLjHJzzU6ci18S9QUfFdFsNL5-qMIDfCHVxwOzda27alYWrX5Gpw0hKUAXM7n18';
const myPlugin = {};
myPlugin.install = (Vue, options) => {

  Vue.prototype.$getFirebaseMessagingToken = () => {};

  if (
    'Notification' in window &&
    'serviceWorker' in navigator &&
    'localStorage' in window &&
    'fetch' in window &&
    'postMessage' in window
  ) {

    const messaging = firebase.messaging();

    Vue.prototype.$getFirebaseMessagingToken = async () => {

      let notificationPermission = Notification.permission;

      await Notification.requestPermission()
        .then(async (permission) => {
          let serviceWorkerRegister = null;
          if (permission === 'granted') {

            await navigator.serviceWorker.register('/firebase-messaging-sw.js')
              .then(async registration => {

                serviceWorkerRegister = registration;
                console.log(`Service Worker Registration (Scope: ${registration.scope})`);
              })
              .catch(error => {
                const msg = `Service Worker Error (${error})`;
                console.error(msg);
              });

            await $store.dispatch('notification/addEventListeners');

          }
          if (serviceWorkerRegister) {
            await messaging.useServiceWorker(serviceWorkerRegister);
          }
          await messaging.getToken()
            .then((currentToken) => {

              if (currentToken) {
                sendTokenToServer(currentToken);
              } else {
                console.info('No Instance ID token available. Request permission to generate one');
                setTokenSentToServer(false);
              }
            })
            .catch(function(error) {
              console.info('An error occurred while retrieving token', error);
              setTokenSentToServer(false);
            });

          notificationPermission = permission;

        })
        .catch(function(error) {
          console.info('Unable to get permission to notify', error);
        });

        return notificationPermission;

    };

    const setTokenSentToServer = (currentToken) => {
      if (currentToken) {
        window.localStorage.setItem('sentFirebaseMessagingToken', currentToken);
      } else {
        window.localStorage.removeItem('sentFirebaseMessagingToken');
      }
    };

    const sendTokenToServer = (currentToken) => {
      if (!isTokenSentToServer(currentToken)) {
        console.log('Sending token to server...');

        RequestManager.Auth.connectFirebase(currentToken)
          .then(() => setTokenSentToServer(currentToken));

      } else {
        console.log('Token already sent to server so won\'t send it again unless it changes');
      }
    };

    const isTokenSentToServer = (currentToken) => {
      return window.localStorage.getItem('sentFirebaseMessagingToken') === currentToken;
    };


    // const channel = new BroadcastChannel('firebase-messaging-sw');
    // channel.addEventListener('message', ({ data }) => {
    //   console.log('Received', data);
    // });

    messaging.onMessage(function (payload) {
      console.log('Message received', payload);
      //$store.dispatch('notification/notificationHandler', payload);


      // Notification.requestPermission(function(permission) {
      //   if (permission === 'granted') {
      //     navigator.serviceWorker.ready.then(function(registration) {
      //       // Copy data object to get parameters in the click handler
      //       payload.data.data = JSON.parse(JSON.stringify(payload.data));
      //
      //       registration.showNotification(payload.data.title, payload.data);
      //     }).catch(function(error) {
      //       // registration failed :(
      //       console.error('ServiceWorker registration failed', error);
      //     });
      //   }
      // });
    });


    messaging.onTokenRefresh(function() {
      messaging.getToken()
        .then(function(refreshedToken) {
          console.log('Token refreshed');
          // Send Instance ID token to app server.
          sendTokenToServer(refreshedToken);
        })
        .catch(function(error) {
          console.error('Unable to retrieve refreshed token', error);
        });
    });

    // getToken();


    Vue.prototype.$messaging = messaging;

  }
};

export default myPlugin;


