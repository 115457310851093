// eslint-disable-next-line no-unused-vars
const RequestDataPrepare = (requestType, requestUrl, requestData) => {
  let reqData = {};
  if (requestType === 'POST' || requestType === 'PUT' || requestType === 'PATCH') {
    reqData.post = requestData;
  } else if (requestType === 'GET') {
    reqData.get = requestData;
  }
  return reqData;
};

export default RequestDataPrepare;
