export const SERVER_TYPE_LOCAL = 'SERVER_TYPE_LOCAL';
export const SERVER_TYPE_DEVELOP = 'SERVER_TYPE_DEVELOP';
export const SERVER_TYPE_PRODUCTION = 'SERVER_TYPE_PRODUCTION';

export const BREAKPOINT = {
  XS: 0,
  SM: 768,
  MD: 992,
  LG: 1200,
  XL: 1620,
};

export const REQUEST_STATUSES = {
  ACTIVE: 'ACT',
  IN_WORK: 'INP',
  CANCELED: 'CNC',
  EXPIRED: 'EXP',
  PAUSED: 'PSD',
  COMPLETED: 'CPD',
  FINISHED: 'FSD'
};

export const USER_INVITE_STATUSES = {
  JOINED: {
    KEY: 'JND',
    VALUE: 'Присоединился'
  },
  AWAIT_COMPANY: {
    KEY: 'AWC',
    VALUE: 'Ожидает подтверждения'
  },
  AWAIT_REGISTER: {
    KEY: 'AWR',
    VALUE: 'Ожидает регистрации'
  },
  REJECTED: {
    KEY: 'RJD',
    VALUE: 'Отклонено'
  },
  REJECTED_COMPANY: {
    KEY: 'RBC',
    VALUE: 'Отколнено компанией'
  }
};


export const ASSISTANCE_PROVIDERS_TYPES = {
  PERSONE: 'PERSONE',
  COMPANY: 'COMPANY',
};

export const CUSTOM_MODES = {
  CUSTOMER: {
    name: 'Заказчик',
    key: 'CUSTOMER'
  },
  PERFORMER: {
    name: 'Исполнитель',
    key: 'PERFORMER'
  }
};
