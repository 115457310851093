import Modal from "@component/Modal/Index";

const Plugin = {
  install(Vue, options = {}) {
    if (Vue.prototype.$modal) {
      return;
    }

    const instance = (Vue, options = {}) => {

      const subscription = new Vue();

      const context = {
        root: null,
        componentName: options.componentName || 'Modal'
      };

      const show = (name, params, listeners) => {
        return new Promise( resolve => {
          subscription.$emit('toggle', name, true, params, resolve, listeners);
        });
      };

      const hide = (name, params, listeners) => {
        return new Promise( resolve => {
          subscription.$emit('toggle', name, false, params, resolve, listeners);
        });
      };

      return {
        context,
        subscription,
        show,
        hide
      };

    };

    const plugin = new instance(Vue, options);


    Vue.component(plugin.context.componentName, Modal);
    Vue.prototype.$modal = plugin;

  }
};

export default Plugin;
