import axios from 'axios';
let _requestCount = 1;
const getRequestId = () => {
  let requestId = _requestCount;
  _requestCount++;
  return requestId;
};

export default {
  namespaced: true,
  state: {
    mobile: '',
    isAuth: false,
    token: ''
  },
  getters: {

  },
  mutations: {
    setMobile(state, num) {
      state.mobile = num;
    },
    setToken(state, token) {
      state.isAuth = !!token;
      state.token = token ? token : '';
    }
  },
  actions: {
    async addMobile({ commit, dispatch }, num) {
      commit('setMobile', num);
    },
    auth({commit, state, dispatch}, token) {
      commit('setToken', token);
      window.localStorage.setItem("token", state.token);
      axios.defaults.headers.common["Authorization"] = 'Token ' + token;
      dispatch('enums/getEnums', null, { root: true });
      // if (!!state.token) {
      //   dispatch('getUser', null, { root: true });
      // }

      return state.isAuth;
    },

    logout({ commit }) {
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('sentFirebaseMessagingToken');
      commit('setToken', '');
      axios.defaults.headers.common["Authorization"] = '';
    },

    reAuth({commit, dispatch}) {
      commit('setToken', localStorage.getItem('token'));
      dispatch('enums/getEnums', null, { root: true });
    },

    // TODO: Изменить название функции
    setToken({commit, dispatch}) {
      commit('setToken', localStorage.getItem('token'));
    }

  },
};
