<template>
  <v-dialog v-model="isShown" @click:outside="close">
    <v-card v-if="name">

      <div class="btn-close" @click="close">
        <svg width="12" height="11" viewBox="0 0 12 11" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1.008 9.375a.71.71 0 000 .992.71.71 0 00.992 0L6 6.36l4.008 4.008a.7.7 0 00.984 0 .696.696 0 000-.992l-4-4.008 4-4a.696.696 0 000-.992.7.7 0 00-.984 0L6 4.383 2 .375a.71.71 0 00-.992 0 .71.71 0 000 .992l4.008 4-4.008 4.008z"/></svg>
      </div>

      <component
        v-bind="params"
        v-on="{
          ...listeners,
          close: () => close()
        }"
        :is="`Modal${name}`"
      ></component>

    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "ModalIndex",

  components: {
    ModalServiceNeed: () => import('@component/Modal/ModalServiceNeed'),
    ModalConfirm: () => import('@/component/Modal/ModalConfirm'),
    ModalEvaluate: () => import('@/component/Modal/ModalEvaluate'),
    ModalChildRequest: () => import('@/component/Modal/ModalChildRequest'),
    ModalErrorMessage: () => import('@/component/Modal/ModalErrorMessage'),
    ModalAccept: () => import('@/component/Modal/ModalAccept'),
    ModalServiceProvide: () => import('@/component/Modal/ModalServiceProvide'),
    ModalEmployeeStopped: () => import('@/component/Modal/ModalEmployeeStopped'),
    ModalInvitationCooperation: () => import('@/component/Modal/ModalInvitationCooperation'),
    ModalViewPhoto: () => import('@/component/Modal/ModalViewPhoto')
  },

  data() {
    return {
      isShown: false,
      params: {},
      listeners: {},
      name: '',
    };
  },
  methods: {
    onToggle(name, state, params, resolve, listeners = {}) {

      if (!state) {
        this.close();
        return;
      }

      this.name = name;
      this.listeners = listeners;

      this.params = {
        // ...this.params,
        ...params,
        resolve
      };

      this.isShown = true;

    },

    close() {
      this.isShown = false;
      this.name = '';
      this.params = {};
      this.listeners = {};
    },

  },
  beforeMount() {
    this.$modal.subscription.$on('toggle', this.onToggle);
  },
  beforeDestroy() {
    this.$modal.subscription.$off('toggle', this.onToggle);
  },
};
</script>

<style lang="scss" scoped>

</style>
