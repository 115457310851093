function prepareResults(data) {
  if (data.results) {
    return data.results;
  } else {
    if (data.length || Object.entries(data).length) {
      return data;
    } else {
      return false;
    }
  }
}

export default SendRequest => {
  const request = {};

  request.createRequest = (userId, payload) => {
    const formData = { ...payload };

    return SendRequest('POST', `/v1/users/${userId}/requests/`, formData, data => {
      return data || { error: true };
    });
  };

  request.removeRequest = (userId, requestId) => {
    return SendRequest('DELETE', `/v1/users/${userId}/requests/${requestId}/`, null, data => {
      return data || { error: true };
    });
  };

  request.updateRequest = (userId, formData) => {
    const requestId = formData.id;
    return SendRequest('PATCH', `/v1/users/${userId}/requests/${requestId}/`, formData, data => {
      return data || { error: true };
    });
  };

  request.getRequests = (userId, params) => {
    return SendRequest('GET', `/v1/users/${userId}/requests/`, params, data => {
      return prepareResults(data) || { error: true };
    });
  };

  request.getRequest = (userId, requestId) => {
    return SendRequest('GET', `/v1/users/${userId}/requests/${requestId}/`, null, data => {
      return data || { error: true };
    });
  };

  request.cancelRequestExecution = (userId, serviceRequestId) => {
    return SendRequest('POST', `/v1/users/${userId}/requests/${serviceRequestId}/canceled/`, null, data => {});
  };


  //TODO: ?Вынести сущность RESPONSE отдельно?
  request.getResponses = (userId, params) => {
    return SendRequest('GET', `/v1/users/${userId}/responses/`, params, data => {
      return prepareResults(data) || { error: true };
    });
  };

  request.getProviderResponses = (userId, params) => {
    return SendRequest('GET', `/v1/users/${userId}/requests/list-responses/`, params, data => {
      return prepareResults(data) || { error: true };
    });
  };

  request.getResponse = (userId, requestId, responseId) => {
    return SendRequest('GET', `/v1/users/${userId}/requests/${requestId}/responses/${responseId}/`, null, data => {
      return prepareResults(data) || { error: true };
    });
  };

  request.getResponsesOfRequest = (userId, requestId, params) => {
    return SendRequest('GET', `/v1/users/${userId}/requests/${requestId}/responses/`, params, data => {
      return prepareResults(data) || { error: true };
    });
  };

  request.acceptResponse = (userId, serviceRequestId, responseId) => {
    return SendRequest('POST', `/v1/users/${userId}/requests/${serviceRequestId}/responses/${responseId}/accepted/`, null, data => {
      return prepareResults(data) || { error: true };
    });
  };

  request.cancelResponse = (userId, serviceRequestId, responseId) => {
    return SendRequest('POST', `/v1/users/${userId}/requests/${serviceRequestId}/responses/${responseId}/canceled/`, null, data => {
      return prepareResults(data) || { error: true };
    });
  };

  request.rateRequest = (userId, serviceRequestId, params) => {
    return SendRequest('POST', `/v1/users/${userId}/requests/${serviceRequestId}/rating/`, params, data => {});
  };

  request.completeWork = (userId, requestId, responseId, params) => {
    const formData = new FormData();

    for (let i = 0; i < params.image.length; i++) {
      formData.append('image', params.image[i]);
    }

    return SendRequest('POST', `/v1/users/${userId}/requests/${requestId}/responses/${responseId}/completed/`, formData, data => {
      return data || { error: true };
    });
  };

  request.customerCompleteWork = (userId, requestId) => {
    return SendRequest('POST', `/v1/users/${userId}/requests/${requestId}/completed/`, null, data => {
      return data || { error: true };
    });
  };

  request.takeWork = (userId, requestId, params) => {
    return SendRequest('POST', `/v1/users/${userId}/requests/${requestId}/responses/`, params, data => {
      return data || { error: true };
    });
  };

  request.createService = (userId, formData) => {
    return SendRequest('POST', `/v1/users/${userId}/services/`, formData, data => {
      return data || { error: true };
    });
  };

  request.updateService = (userId, serviceId, formData) => {
    return SendRequest('PATCH', `/v1/users/${userId}/services/${serviceId}/`, formData, data => {
      return data || { error: true };
    });
  };

  request.removeService = (userId, serviceId) => {
    return SendRequest('DELETE', `/v1/users/${userId}/services/${serviceId}/`, null, data => {
      return data || { error: true };
    });
  };

  request.getServices = userId => {
    return SendRequest('GET', `/v1/users/${userId}/services/`, null, data => {
      return prepareResults(data) || { error: true };
    });
  };

  return request;
};
