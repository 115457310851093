
// DEFAULT
export const AUTH_ONLY = 'AUTH_ONLY';

// MENU
export const MENU__USER_ACTION = 'MENU__USER_ACTION';

// ACTION
export const ACTION__HOME__PAY    = 'ACTION__HOME__PAY';
export const ACTION__HOME__PRINT  = 'ACTION__HOME__PRINT';
