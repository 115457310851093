import Vue from 'vue';

// глобально инитим классы
import '@enum';
import '@entity';

// глобально инитим константу CONST
import '@/core/constGlobal.js';

// Library
import '@library/dayjs';

// глобальный реквест менеджер
import '@service/RequestManager';

// дефолтный импорт

import store  from '@store';
import router from '@router';

// filter
import '@/core/filter';

// Plugins
// import '@plugin/bootstrap-vue';
// import '@plugin/v-slim-dialog';
// import '@plugin/vue-js-modal';
// import '@plugin/element-ui';
// import '@plugin/vue-suggestion';
// import '@plugin/vue-click-outside';
import '@plugin/vue-meta';
import '@plugin/v-mask';
import vuetify from '@plugin/vuetify';

import Modal from '@plugin/modal';
Vue.use(Modal);

import firebase from '@plugin/firebase';
Vue.use(firebase);

// import registerServiceWorker from "@plugin/registerServiceWorker";
// Vue.use(registerServiceWorker);

import App    from '@layout';

import '@permission/init';

// element ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/ru-RU';
Vue.use(ElementUI, { locale });

// vue-custom-scrollbar
import vueCustomScrollbar from 'vue-custom-scrollbar';
import "vue-custom-scrollbar/dist/vueScrollbar.css";
Vue.use(vueCustomScrollbar);
Vue.component('vue-custom-scrollbar', vueCustomScrollbar);

// v-click-outside
import ClickOutside from 'vue-click-outside';
Vue.directive('ClickOutside', ClickOutside);

// глобальные стили
import '@style/init.scss';

import vGanttChart from 'v-gantt-chart';
Vue.use(vGanttChart);

Vue.config.productionTip = false;

global.Vue = Vue;
global.VueApp = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
