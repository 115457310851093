function prepareResults(data) {
  if (data.results) {
    return data.results;
  } else {
    if (data.length) {
      return data;
    } else {
      return false;
    }
  }
}


export default SendRequest => {
  const request = {};

  request.getRequestsForCompanies = (params) => {
    return SendRequest('GET', `/v1/companies/requests/`, params, data => {
      return prepareResults(data) || { error: true };
    });
  };

  request.getResponsesForCompanies = (params) => {
    return SendRequest('GET', `/v1/companies/responses/`, params, data => {
      return prepareResults(data) || { error: true };
      //return data;
    });
  };

  return request;
};
