export default SendRequest => {

  const request = {};

  request.getList = (num = 10) => {
    return SendRequest('GET', `/v1/notifications/`, {}, data => data.results || { error: true });
  };

  request.markAsRead = (pushId) => {
    return SendRequest('PATCH', `/v1/notifications/${pushId}/`, { is_viewed: true }, data => data || { error: true });
  };

  return request;
};
