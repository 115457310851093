import dayjs from 'dayjs';
require('dayjs/locale/ru');

const relativeTime = require('dayjs/plugin/relativeTime');
const utc = require('dayjs/plugin/utc'); // dependent on utc plugin
const customParseFormat = require('dayjs/plugin/customParseFormat');
const weekday = require('dayjs/plugin/weekday');
const duration = require('dayjs/plugin/duration');

dayjs.extend(duration);
dayjs.extend(weekday);
dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

dayjs.locale('ru');
global.DateTime = dayjs;
