export default (SendRequest) => {

  const request = {};

  request.create = (userId, data) => {

    const formData = new FormData();

    for (const key in data) {
      formData.append(key, data[key]);
    }


    return SendRequest('POST', `/v1/users/${userId}/documents/certificates/`, formData, response => {
      return [response];
    });
  };

  request.update = (userId, certificateId, formData) => {
    return SendRequest('PATCH', `/v1/users/${userId}/documents/certificates/${certificateId}/`, formData, data => {
      return data;
    });
  };

  request.remove = (userId, certificateId) => {
    return SendRequest('DELETE', `/v1/users/${userId}/documents/certificates/${certificateId}/`, null, data => {
      return data;
    });
  };

  request.getList = (userId) => {
    return SendRequest('GET', `/v1/users/${userId}/documents/certificates/`, null, ({ results }) => {
      return results;
    });
  };


  return request;
};
