import axios from 'axios';
let _requestCount = 1;
const getRequestId = () => {
  let requestId = _requestCount;
  _requestCount++;
  return requestId;
};

export default {
  namespaced: true,
  state: {
    user: {},
    serviceRequestList: [],
    family: [],
    isAddedServiceRequest: null,
  },
  getters: {
    family(state) {
      return state.family;
    },
    company(state) {
      return state.user.company;
    }
  },
  mutations: {
    setUser(state, pl) {
      state.user = pl;
    },
    setFamily(state, pl) {
      state.family = pl;
    },

    setIsAddedServiceRequest(state) {
      state.isAddedServiceRequest = DateTime().unix();
    },

    setServiceRequestList(state, serviceRequestList) {
      state.serviceRequestList = serviceRequestList;
    },

    updateServiceRequest(state, serviceRequest) {
      state.serviceRequestList = state.serviceRequestList.map(service => {
        if (service.id === serviceRequest.id) {
          return serviceRequest;
        }
        return service;
      });
    },
    updateChildServiceRequest(state, { parentServiceRequestId, response }) {
      const parentRequest = state.serviceRequestList.find(el => el.id === parentServiceRequestId);

      if (parentRequest) {
        const indexChildRequest = parentRequest.child.findIndex(el => el.id === response.id);

        if (indexChildRequest + 1)  {
          parentRequest.child.splice(indexChildRequest, 1, response);
        }
      }

      // state.serviceRequestList = state.serviceRequestList.map((service) => {
      //   if (service.id === serviceRequest.id) {
      //     return serviceRequest;
      //   }
      //   return service;
      // });
    },

    removeServiceRequest(state, serviceRequestIndex) {
      state.serviceRequestList.splice(serviceRequestIndex, 1);
    },

  },
  actions: {
    async getUser({ commit }, userId = null) {
      const user = await RequestManager.User.getUser(userId);
      if (userId) {
        return user;
      }
      commit('setUser', user);
    },

    async addFamily({ commit }, pl) {
      commit('setFamily', pl);
    },

    async getFamily({ state, commit }) {
      const family = await RequestManager.User.getFamily(state.user.id);
      commit('setFamily', family.result);
      return family.result;
    },

    async createServiceRequest({ state, dispatch }, serviceRequest) {
      await RequestManager.Service.createRequest(state.user.id, serviceRequest);
      await dispatch('getServiceRequestList');
    },

    async updateServiceRequest({ state, commit }, serviceRequest) {
      await RequestManager.Service.updateRequest(state.user.id, serviceRequest).then(response => {
        commit('updateServiceRequest', response);
      });
    },
    async updateChildServiceRequest({ state, commit }, { childServiceRequest, parentServiceRequestId }) {
      await RequestManager.Service.updateRequest(state.user.id, childServiceRequest).then(response => {
        commit('updateChildServiceRequest', { parentServiceRequestId, response });
      });
    },

    async getServiceRequestList({ state, commit }, params = {}) {
      const serviceRequestList = await RequestManager.Service.getRequests(state.user.id, {...params, parent_is_null: true });
      commit('setServiceRequestList', serviceRequestList);
    },

    removeServiceRequest({ state, commit }, serviceRequestIndex) {
      const serviceRequestId = state.serviceRequestList[serviceRequestIndex].id;
      RequestManager.Service.removeRequest(state.user.id, serviceRequestId).then(() => {
        commit('removeServiceRequest', serviceRequestIndex);
      });
    },

    async updateUserProfileData({ commit, state }, profileData) {
      let id = profileData?.id ?? state.user.id;
      delete profileData.id;
      const userResponseData = await RequestManager.User.updateUser(id, profileData);
      commit('setUser', userResponseData);
      return userResponseData;
    },

    async updateAnotherUserProfileData({ commit, state }, { id, user }) {
      const userResponseData = await RequestManager.User.updateUser(id, { user });
      return userResponseData;
    },
  },
};
