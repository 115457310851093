function prepareResults(data) {
  if (data.results) {
    return data.results;
  } else {
    if (data.length) {
      return data;
    } else {
      return false;
    }
  }
}

export default SendRequest => {
  const request = {};

  request.getStates = id => {
    return SendRequest('GET', `/v1/users/${id}/family/get_relationships/`, null, data => {
      return data || { error: true };
    });
  };

  request.getCountries = () => {
    return SendRequest('GET', `/v1/locations/`, null, data => {
      return prepareResults(data) || { error: true };
    });
  };

  request.getRegions = countryId => {
    return SendRequest('GET', `/v1/locations/${countryId}/region/`, null, data => {
      return prepareResults(data) || { error: true };
    });
  };

  request.getAreas = (countryId, regionId) => {
    return SendRequest('GET', `/v1/locations/${countryId}/region/${regionId}/area/`, null, data => {
      return prepareResults(data) || { error: true };
    });
  };

  request.getCities = (countryId, regionId, areaId) => {
    return SendRequest('GET', `/v1/locations/${countryId}/region/${regionId}/area/${areaId}/city/`, null, data => {
      return prepareResults(data) || { error: true };
    });
  };

  request.getGenders = () => {
    return SendRequest('GET', `/v1/users/get_genders/`, null, data => {
      return prepareResults(data) || { error: true };
    });
  };

  request.getLegalForms = () => {
    return SendRequest('GET', `/v1/companies/legal/`, null, data => {
      return prepareResults(data) || { error: true };
    });
  };

  request.getCategories = () => {
    return SendRequest('GET', `/v1/categories/`, null, data => {
      return prepareResults(data) || { error: true };
    });
  };

  request.getRepeats = () => {
    return SendRequest('GET', `/v1/work/requests/get_repeats/`, null, data => {
      return prepareResults(data) || { error: true };
    });
  };

  request.getRequestsStatuses = () => {
    return SendRequest('GET', `/v1/work/requests/get_statuses/`, null, data => {
      return prepareResults(data) || { error: true };
    });
  };

  return request;
};
