import Auth from './group/Auth';
import Register from './group/Register';
import User from './group/User';
import Enums from './group/Enums';
import Service from './group/Service';
import Family from './group/Family';
import Timetable from './group/Timetable';
import Certificate from './group/Certificate';
import Company from './group/Company';
import WorkRequests from './group/WorkRequests';
import Invite from './group/Invite';
import Notification from "./group/Notification";

const RequestManager = SendRequest => {
  return {
    Auth: Auth(SendRequest),
    Register: Register(SendRequest),
    User: User(SendRequest),
    Enums: Enums(SendRequest),
    Service: Service(SendRequest),
    Family: Family(SendRequest),
    Timetable: Timetable(SendRequest),
    Certificate: Certificate(SendRequest),
    Company: Company(SendRequest),
    WorkRequests: WorkRequests(SendRequest),
    Invite: Invite(SendRequest),
    Notification: Notification(SendRequest)
  };
};

export default RequestManager;
