import Vue from 'vue';
import notification from "@mixin/notification";

export default {
  namespaced: true,
  state: {
    notificationList: []
  },
  getters: {},
  mutations: {
    setNotificationList(state, notificationList) {
      state.notificationList = notificationList;
    },
  },
  actions: {

    markNotificationAsRead({ dispatch }, { result, push_id }) {
      if (typeof result !== 'boolean') return;
      RequestManager.Notification.markAsRead(push_id).then(() => {
        dispatch('getNotificationList');
      });
    },

    async getNotificationList({ commit }) {
      const notificationList = await RequestManager.Notification.getList();
      commit('setNotificationList', notificationList);
      return notificationList;
    },

    addEventListeners({ dispatch }) {
      const channel = new BroadcastChannel('firebase-messaging-sw');

      channel.addEventListener('message', function ({ data }) {
        dispatch('getNotificationList');
      });

      this._vm.$messaging.onMessage(function (payload) {
        console.log('Message received', payload);
        dispatch('notificationHandler', payload);
        dispatch('getNotificationList');
      });
    },

    async notificationHandler({ dispatch, rootState }, payload) {

      if (!rootState.user?.user?.is_registered) {
        return;
      }

      const { data, notification: { title, body } } = payload;
      const { type, invite_id, push_id, user_id } = data;

      if (type === 'SIC') {

        await this._vm.$modal.show('InvitationCooperation', {
          title,
          text: body,
          invite_id
        }).then((result) => {
          dispatch('markNotificationAsRead', { result, push_id });
        });
        return;
      } else if (type === 'UEC') {
        const user = await dispatch('user/getUser', user_id, { root: true });
        await this._vm.$modal.show('EmployeeStopped', {
          title,
          text: body,
          name: `${ user.first_name } ${ user.last_name }`,
          photo: user.photo
        })
          .then((result) => {
            dispatch('markNotificationAsRead', { result, push_id });
          });
        return;
      }

      const params = {
        title,
        text: body,
      };

      if (notification.methods.isLink(type)) {
        params.to = notification.methods.generateRoute(data);
      }


      await this._vm.$modal.show('Accept', params)
        .then((result) => {
          dispatch('markNotificationAsRead', { result, push_id });
        });
    }
  }
};
