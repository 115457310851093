import * as pageName from "@router/variable";

const routes = [
  {
    path: "/registration/role",
    name: pageName.ASSISTANCE,
    component: () => import('@page/Assistance/Assistance')
  },

  // Заказчик

  {
    path: "/registration/customer/type",
    name: pageName.ASSISTANCE_NEED_SELECT,
    component: () => import('@page/Assistance/Need/AssistanceNeedSelect.vue')
  },

  {
    path: '/registration/customer',
    name: pageName.REGISTRATION_CUSTOMER,
    beforeEnter: (to, from, next) => {
      const components = {};
      components.default = () => {
        if (!to.query?.type) {
          return next({ name: pageName.ASSISTANCE_NEED_SELECT });
        }

        if (to.query.type === 'relative') {
          return import('@page/Assistance/Need/AssistanceNeedFromFriend.vue');
        }

        return import('@page/Assistance/Need/AssistanceNeedFromMe.vue');
      };

      to.matched[0].components = components;
      next();
    }
  },


  // Исполнитель

  {
    path: '/registration/executor/type',
    name: pageName.ASSISTANCE_PROVIDE_SELECT,
    component: () => import('@page/Assistance/Provide/AssistanceProvideSelect.vue'),
    meta: {
      theme: 'red'
    }
  },

  {
    path: '/registration/executor',
    name: pageName.REGISTRATION_EXECUTOR,
    meta: {
      theme: 'red'
    },
    beforeEnter: (to, from, next) => {
      const components = {};
      components.default = () => {
        if (!to.query?.type) {
          return next({ name: pageName.ASSISTANCE_PROVIDE_SELECT });
        }

        if (to.query.type === 'entity') {
          return import('@page/Assistance/Provide/AssistanceProvideFromCompany');
        }

        return import('@page/Assistance/Provide/AssistanceProvideFromPerson');
      };

      to.matched[0].components = components;
      next();
    }
  },

  {
    path: "/registration/confirm-email",
    name: pageName.CONFIRM_EMAIL,
    component: () => import('@page/Auth/ConfirmEmail'),
  },
];


export default routes.map(route => {
  const meta = {
    onlyRegistered: false,
    layout: 'FULL_SIZE',
    ...route?.meta
  };

  return {
    ...route,
    meta
  };
});
