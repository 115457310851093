export const PRELOADER  = 'PRELOADER';

// auth
export const AUTH_WELCOME   = 'AUTH_WELCOME';
export const AUTHORIZATION  = 'AUTHORIZATION';
export const REGISTRATION   = 'REGISTRATION';
export const CONFIRM_PHONE  = 'CONFIRM_PHONE';
export const CONFIRM_EMAIL  = 'CONFIRM_EMAIL';
export const RESTORE_ACCESS = 'RESTORE_ACCESS';
export const INVITATION     = 'INVITATION';

// export const PASSWORD_RESET = 'PASSWORD_RESET';

// auth
export const ASSISTANCE                   = 'ASSISTANCE';

export const ASSISTANCE_NEED_SELECT       = 'ASSISTANCE_NEED_REGISTRATION_SELECT';
export const ASSISTANCE_NEED_FROM_ME      = 'REGISTRATION_CUSTOMER';
export const ASSISTANCE_NEED_FROM_FRIEND  = 'REGISTRATION_CUSTOMER';

export const ASSISTANCE_PROVIDE_SELECT        = 'ASSISTANCE_PROVIDE_REGISTRATION_SELECT';
export const ASSISTANCE_PROVIDE_FROM_PERSON   = 'REGISTRATION_EXECUTOR';
export const ASSISTANCE_PROVIDE_FROM_COMPANY  = 'REGISTRATION_EXECUTOR';

// TODO
export const ASSISTANCE_REGISTRATION_READY    = 'ASSISTANCE_REGISTRATION_READY';
export const ASSISTANCE_REGISTRATION_NO_READY    = 'ASSISTANCE_REGISTRATION_NO_READY';

// lk need help

export const ASSISTANCE_NEED_APPLICATIONS           = 'APPLICATIONS';
export const ASSISTANCE_NEED_APPLICATION            = 'APPLICATION';

export const ASSISTANCE_NEED_RESPONSES                  = 'RESPONSES';
export const ASSISTANCE_NEED_RESPONSE                   = 'RESPONSE';
export const ASSISTANCE_NEED_APPLICATION_RESPONSES      = 'ASSISTANCE_NEED_APPLICATION_RESPONSES';
// export const ASSISTANCE_NEED_CREATE_APPLICATION         = 'ASSISTANCE_NEED_CREATE_APPLICATION';
export const ASSISTANCE_NEED_EDIT_PROFILE               = 'EDIT_PROFILE';

// lk provide help
export const ASSISTANCE_PROVIDE_APPLICATIONS            = 'APPLICATIONS';
export const ASSISTANCE_PROVIDE_APPLICATION             = 'APPLICATION';
export const ASSISTANCE_PROVIDE_EDIT_PROFILE            = 'EDIT_PROFILE';
export const ASSISTANCE_PROVIDE_LIST_INVITATIONS        = 'ASSISTANCE_PROVIDE_LIST_INVITATIONS';
export const ASSISTANCE_PROVIDE_INVITATION              = 'ASSISTANCE_PROVIDE_INVITATION';

//
export const ASSISTANCE_PROVIDE_COMPANY_APPLICATIONS     = 'APPLICATIONS';
export const ASSISTANCE_PROVIDE_COMPANY_APPLICATION      = 'APPLICATION';
export const ASSISTANCE_PROVIDE_COMPANY_COMPLETED_APPLICATIONS  = 'ASSISTANCE_PROVIDE_COMPANY_COMPLETED_APPLICATIONS';
export const ASSISTANCE_PROVIDE_COMPANY_COMPLETED_APPLICATION   = 'ASSISTANCE_PROVIDE_COMPANY_COMPLETED_APPLICATION';
export const ASSISTANCE_PROVIDE_COMPANY_RESPONSES               = 'RESPONSES';
export const ASSISTANCE_PROVIDE_COMPANY_RESPONSE                = 'RESPONSE';
export const ASSISTANCE_PROVIDE_COMPANY_EMPLOYEES               = 'ASSISTANCE_PROVIDE_COMPANY_EMPLOYEES';
export const ASSISTANCE_PROVIDE_COMPANY_EMPLOYEE                = 'ASSISTANCE_PROVIDE_COMPANY_EMPLOYEE';
export const ASSISTANCE_PROVIDE_COMPANY_ADD_EMPLOYEE            = 'ASSISTANCE_PROVIDE_COMPANY_ADD_EMPLOYEE';
export const ASSISTANCE_PROVIDE_COMPANY_SEARCH_EMPLOYEE         = 'ASSISTANCE_PROVIDE_COMPANY_SEARCH_EMPLOYEE';
export const ASSISTANCE_PROVIDE_COMPANY_DATA_EMPLOYEE           = 'ASSISTANCE_PROVIDE_COMPANY_DATA_EMPLOYEE';
export const ASSISTANCE_PROVIDE_COMPANY_EMPLOYEE_SCHEDULE       = 'ASSISTANCE_PROVIDE_COMPANY_EMPLOYEE_SCHEDULE';
export const ASSISTANCE_PROVIDE_COMPANY_EDIT_PROFILE            = 'EDIT_PROFILE';

// TEST
//Модальное окно "Сотрудник прекратил с вами сотрудничество,
// вышел из организации"
export const MODAL_EMPLOYEE_STOPPED  = 'MODAL_EMPLOYEE_STOPPED';
// Модальное окно Приглашение к сотрудничеству от компании “Гарант Помощь”
export const MODAL_INVITATION_COOPERATION  = 'MODAL_INVITATION_COOPERATION';

export const TEST_POPUP_NEED_CONTENT_1  = 'TEST_POPUP_NEED_CONTENT_1';
export const TEST_POPUP_NEED_CONTENT_2  = 'TEST_POPUP_NEED_CONTENT_2';

export const TEST_POPUP_PROVIDE_CONTENT_1  = 'TEST_POPUP_PROVIDE_CONTENT_1';
export const TEST_POPUP_PROVIDE_CONTENT_2  = 'TEST_POPUP_PROVIDE_CONTENT_2';
export const TEST_POPUP_PROVIDE_CONTENT_3  = 'TEST_POPUP_PROVIDE_CONTENT_3';
// TODO: 34.0_Home_Screen_I_Help_0.1 модальное окно
export const TEST_POPUP_PROVIDE_MAP  = 'TEST_POPUP_PROVIDE_MAP';

// TODO: Модерация
export const TECHNICAL_SUPPORT  = 'TECHNICAL_SUPPORT';
export const TECHNICAL_SUPPORT_CARD  = 'TECHNICAL_SUPPORT_CARD';
export const TECHNICAL_SUPPORT_QUESTION  = 'TECHNICAL_SUPPORT_QUESTION';
export const TECHNICAL_ADD_PHOTO  = 'TECHNICAL_ADD_PHOTO';
// system
export const NOT_FOUND  = 'NOT_FOUND';
export const HOME = 'PRELOADER';


export const APPLICATIONS = 'APPLICATIONS';
export const APPLICATION = 'APPLICATION';
export const RESPONSES = 'RESPONSES';
export const RESPONSE = 'RESPONSE';
export const EDIT_PROFILE = 'EDIT_PROFILE';
export const REGISTRATION_CUSTOMER = 'REGISTRATION_CUSTOMER';
export const REGISTRATION_EXECUTOR = 'REGISTRATION_EXECUTOR';
