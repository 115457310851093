import Vue from 'vue';
import Router from 'vue-router';
import $store from '@store';
import routes from '@router/routes';
import * as $routeName from "@router/variable";
import * as pageName from "@router/variable";
import {isHelper} from "@router/helpers";

Vue.use(Router);
Vue.prototype.$routeName = $routeName;

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '*',
      component: () => import('@page/System/NotFound'),
      meta: {
        layout: 'DEFAULT',
      },
    }
  ].concat(routes),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});


router.beforeEach(async (to, from, next) => {

  await $store.dispatch('enums/getEnums');
  await $store.dispatch('auth/setToken');

  const isAuthenticated = $store.state.auth.isAuth;

  if (!Object.keys($store.state.user.user).length && isAuthenticated) {
    await $store.dispatch('user/getUser');
  }

  const isRegistered = $store.state.user.user?.is_registered;
  const isHelper = $store.state.user.user?.helper_mode;
  const isCompany = $store.state.user.user?.company;

  const onlyLoggedOut = to.matched.some(record => record.meta.onlyLoggedOut);
  const onlyRegistered = to.matched.some(record => record.meta.onlyRegistered);
  const onlyHelper = to.matched.some(record => record.meta.onlyHelper);
  const onlyCompany = to.matched.some(record => record.meta.onlyCompany);

  const isPublic = to.matched.some(record => record.meta.public);

  if (!isPublic && !isAuthenticated) {
    return next({
      name: pageName.HOME
    });
  }

  if (isAuthenticated && onlyLoggedOut
    || isRegistered && !onlyRegistered
    || !isHelper && onlyHelper
    || !isCompany && onlyCompany
  ) {

    return next({
      name: pageName.APPLICATIONS
    });
  }

  if (!isRegistered && onlyRegistered) {

    return next({
      name: pageName.ASSISTANCE
    });

  }

  if (isHelper && isRegistered) to.matched[0].meta.theme = 'red';
  next();
});

export default router;
