export default {
  namespaced: true,
  state: {
    view: 'list',
  },
  mutations: {
    setView(state, view) {
      if (['list', 'map'].includes(view)) {
        throw new Error('View must by `list` or `map`');
      }
      state.view = view;
    }
  }
};
