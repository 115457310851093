import * as pageName from "@router/variable";

const routes = [
  {
    path: "/",
    name: pageName.PRELOADER,
    component: () => import('@page/System/PreloaderPage')
  },

  {
    path: "/registration",
    name: pageName.REGISTRATION,
    component: () => import('@page/Auth/Registration')
  },

  {
    path: "/authorization",
    name: pageName.AUTHORIZATION,
    component: () => import('@page/Auth/Authorization')
  },

  {
    path: "/confirm-phone",
    name: pageName.CONFIRM_PHONE,
    component: () => import('@page/Auth/ConfirmPhone'),
  },

  {
    path: "/invitation",
    name: pageName.INVITATION,
    component: () => import('@page/Auth/Invitation'),
    meta: {
      theme: 'red'
    },
    beforeEnter: (to, from, next) => {
      if (!to.query?.invite_id) {
        return next({ name: pageName.NOT_FOUND });
      }
      next();
    }
  },

];


export default routes.map(route => {
  const meta = {
    public: true,
    onlyLoggedOut: true,
    layout: 'FULL_SIZE',
    ...route?.meta
  };

  return {
    ...route,
    meta
  };
});
