import * as pageName from "@router/variable";
import notRegisteredRoutes from "./notRegistered";
import {
  sidebar,
  template,
  isCompany,
  isHelper
} from '@router/helpers';

const routes = [
  {
    path: '/profile',
    redirect: { name: pageName.EDIT_PROFILE }
  },

  {
    path: '/profile/edit',
    name: pageName.EDIT_PROFILE,
    component: () => template('EditProfile'),
    meta: {
      layout: 'HEADER',
    }
  },

  {
    path: '/applications',
    name: pageName.APPLICATIONS,
    component: () => template('Applications')
  },

  {
    path: '/applications/:applicationId',
    name: pageName.APPLICATION,
    component: () => template('Application')
  },

  /**
   ** Роуты только для заказчика и исполнителя (компании)
   **/

  {
    path: '/responses',
    name: pageName.RESPONSES,
    beforeEnter: (to, from, next) => {
      const components = { sidebar };
      components.default = () => {
        if (isHelper() && !isCompany()) {
          return next({ name: pageName.APPLICATIONS });
        }

        if (!isHelper()) {
          return import('@page/LkAssistanceNeed/Responses.vue');
        }

        return import('@page/LkAssistanceProvideCompany/Responses.vue');
      };

      to.matched[0].components = components;
      next();
    }
  },

  {
    path: '/applications/:applicationId/responses/:responseId',
    name: pageName.RESPONSE,
    beforeEnter: (to, from, next) => {
      const components = { sidebar };
      components.default = () => {
        if (isHelper() && !isCompany()) {
          return next({ name: pageName.APPLICATIONS });
        }

        if (!isHelper()) {
          return import('@page/LkAssistanceNeed/Response.vue');
        }

        return import('@page/LkAssistanceProvideCompany/Response.vue');
      };

      to.matched[0].components = components;
      next();
    }
  },

  /**
   ** Роуты только для исполнителя (физического лица)
   **/

  {
    path: '/invitations',
    name: pageName.ASSISTANCE_PROVIDE_LIST_INVITATIONS,
    component: () => import('@page/LkAssistanceProvidePerson/ListInvitations.vue'),
    meta: {
      onlyHelper: true
    }
  },

  {
    path: '/invitations/:inviteId',
    name: pageName.ASSISTANCE_PROVIDE_INVITATION,
    component: () => import('@page/LkAssistanceProvideCompany/CompanyDataEmployee.vue'),
    meta: {
      onlyHelper: true
    }
  },

  /**
   ** Роуты только для компании
   **/

  {
    path: '/employees',
    name: pageName.ASSISTANCE_PROVIDE_COMPANY_EMPLOYEES,
    component: () => import('@page/LkAssistanceProvideCompany/Employees.vue'),
    meta: {
      onlyHelper: true,
      onlyCompany: true
    }
  },

  {
    path: '/employees/add',
    name: pageName.ASSISTANCE_PROVIDE_COMPANY_ADD_EMPLOYEE,
    component: () => import('@page/LkAssistanceProvideCompany/AddEmployee.vue'),
    meta: {
      onlyHelper: true,
      onlyCompany: true
    }
  },

  {
    path: '/employees/schedule',
    name: pageName.ASSISTANCE_PROVIDE_COMPANY_EMPLOYEE_SCHEDULE,
    component: () => import('@page/LkAssistanceProvideCompany/EmployeeSchedule.vue'),
    meta: {
      onlyHelper: true,
      onlyCompany: true
    }
  },

  {
    path: '/employees/search',
    name: pageName.ASSISTANCE_PROVIDE_COMPANY_SEARCH_EMPLOYEE,
    component: () => import('@page/LkAssistanceProvideCompany/EmployeeSearch.vue'),
    meta: {
      onlyHelper: true,
      onlyCompany: true
    }
  },

].concat(notRegisteredRoutes);


export default routes.map(route => {
  const meta = {
    public: false,
    onlyRegistered: true,
    onlyHelper: false,
    onlyCompany: false,
    layout: 'SIDEBAR',
    ...route?.meta
  };

  let components = {};

  if (route?.component) {
    components = {
      sidebar,
      default: route.component
    };
  }

  return {
    ...route,
    meta,
    components
  };
});
