import Vue from 'vue';

import formatTextSlice from './modules/formatTextSlice';
Vue.filter('formatTextSlice', formatTextSlice);

import formatDateFull from './modules/formatDateFull';
Vue.filter('formatDateFull', formatDateFull);

import formatTime from './modules/formatTime';
Vue.filter('formatTime', formatTime);

import formatDate from './modules/formatDate';
Vue.filter('formatDate', formatDate);
