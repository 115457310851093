import Vue from "vue";
import Vuex from "vuex";

// modules
import loading from "./system/loading";
import auth from "./system/auth";
import register from "./modules/register";
import user from "./modules/user";
import enums from "./modules/enums";
import invite from "@store/modules/invite";
import notification from "@store/modules/notification";
import applications from "./modules/applications";
// import system from "./modules/system";

// справочники
// import cityCollection               from "@/core/store/modules/cityCollection";
// import ConferenceTagCollection      from "@/core/store/modules/ConferenceTagCollection";
// import ConferenceUserRoleCollection from "@/core/store/modules/ConferenceUserRoleCollection";

// demo



Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

const modules = {
  loading,
  auth,
  register,
  user,
  enums,
  invite,
  notification,
  applications,
};

const store = new Vuex.Store({
  modules: modules,
  strict: debug,
});

// Автоматически запустить действие `init` для каждого существующего модуля
for (const moduleName of Object.keys(modules)) {
  if (modules[moduleName].actions && modules[moduleName].actions.init) {
    store.dispatch(`${moduleName}/init`);
  }
}

export default store;
