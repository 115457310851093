import axios from 'axios';

let _requestCount = 1;

const getRequestId = () => {
  let requestId = _requestCount;
  _requestCount++;
  return requestId;
};

const dataLoaded = () => {
  return new Promise((resolve, reject) => {
    reject('Data already loaded');
  });
};

const prepareEnums = (promises, names) => {
  const result = {};

  promises.forEach(({ status, value }, i) => {
    if (status === 'fulfilled') {
      result[names[i]] = value;
    }
  });

  return result;
};

export default {
  namespaced: true,
  state: {
    //TODO - хардкод
    country: {
      countries: ['Страна 1', 'Страна 2', 'Страна 3', 'Страна 4'],
      cities: ['City 1', 'City 2', 'City 3', 'City 4'],
      districts: ['D1', 'D2', 'D3', 'D4'],
      regions: ['1', '2', '3', '4'],
      forms: ['1', '2', '3'],
      ogrns: ['123456789', '234567890', '345678901', '456789012'],
      services: ['Услуга 1', 'Услуга 2', 'Услуга 3', 'Услуга 4'],
    },
    legalForms: [],
    countries: [],
    categories: [],
    services: [],
    cities: null,
    districts: null,
    regions: null,
    genders: [],
    states: [],
    repeats: [],
    requestsStatuses: [],
  },
  getters: {
    services(state) {
      return state.categories.reduce((result, category) => {
        result.push(...category.responsibility);
        return result;
      }, []);
    },
    countries(state) {
      return state.countries;
    },
    legalForms(state) {
      return state.legalForms;
    },
    findGender(state) {
      return (key) => {
        return state.genders.find((gender) => gender.key === key);
      };
    },
    findStates(state) {
      return (key) => {
        return state.states.find((gender) => gender.key === key);
      };
    }
  },
  mutations: {
    setEnums(state, pl) {
      Object.entries(pl).forEach(([key, val]) => {
        state[key] = val;
      });
    },
  },
  actions: {
    async getCategories({ commit, state }) {
      const { categories } = state;

      if (categories.length) return;

      const names = ['categories'];

      const promises = await Promise.allSettled([RequestManager.Enums.getCategories()]);

      commit('setEnums', prepareEnums(promises, names));
    },

    async getRepeats({ commit, state }) {
      if (state.repeats.length) return;

      const repeats = await RequestManager.Enums.getRepeats();
      commit('setEnums', { repeats });
    },

    async getStates({ commit, state, rootState }) {
      if (state.states.length) return;

      const states = await RequestManager.Enums.getStates(rootState.user.user.id);

      commit('setEnums', { states });
    },

    async getRequestsStatuses({ commit, state }) {
      if (state.requestsStatuses.length) return;

      const requestsStatuses = await RequestManager.Enums.getRequestsStatuses();

      commit('setEnums', { requestsStatuses });
    },

    async getEnums({ commit, state, dispatch }) {
      const { categories, countries, genders, legalForms } = state;
      const names = ['countries', 'genders', 'legalForms'];
      const promises = await Promise.allSettled([
        !countries.length ? RequestManager.Enums.getCountries() : dataLoaded(),
        !genders.length ? RequestManager.Enums.getGenders() : dataLoaded(),
        !legalForms.length ? RequestManager.Enums.getLegalForms() : dataLoaded(),
        !categories.length ? dispatch('getCategories') : dataLoaded()
      ]);

      commit('setEnums', prepareEnums(promises, names));
    },
  },
};
