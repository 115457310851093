import * as $routeName from '@router/variable';

export default {
  methods: {

    isLink(type) {
      return ['RSC', 'FRR', 'NRR', 'CPD', 'SRR', 'SIC', 'NCR', 'CNR'].includes(type);
    },

    generateRoute(data) {
      if (['RSC', 'CPD', 'SRR', 'NCR', 'CNR'].includes(data.type)) {
        return {
          name: $routeName.APPLICATION,
          params: { applicationId: data.request_id }
        };

        // Первый отклик на заявку
      } else if (data.type === 'FRR') {
        return {
          name: $routeName.RESPONSE,
          params: {
            applicationId: data.request_id,
            responseId: data.response_id
          }
        };
        // n откликов на заявку
      } else if (data.type === 'NRR') {
        return {
          name: $routeName.RESPONSES,
          query: {
            request: data.request_id
          }
        };
        // Приглашение в компанию
      } else if (data.type === 'SIC') {
        return {
          name: $routeName.ASSISTANCE_PROVIDE_INVITATION,
          params: { inviteId: data.invite_id }
        };
      }
      return { name: $routeName.HOME };
    },

  }
};
