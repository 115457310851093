export default SendRequest => {
  const request = {};

  request.getUser = (userId = null) => {

    return SendRequest('GET', `/v1/users/${ userId ?? 'me' }/`, {}, data => {
      return data;
    });
  };

  request.getFamily = userId => {
    return SendRequest('GET', `/v1/users/${userId}/family/`, null, data => {
      return data;
    });
  };

  request.updateCurrentLocation = (userId, formData) => {
    return SendRequest('PATCH', `/v1/users/${userId}/`, formData, data => {
      return data;
    });
  };

  // TODO Delete this method, use Register (sendUser) insted
  request.updateUser = (userId, { user = null, documents = null }) => {
    const userData = new FormData();

    console.log(user, documents);

    if (user) {
      Object.entries(user).forEach(([k, v]) => {
        if (v || typeof v === "boolean") {
          userData.append(k, v);
        }
      });
    }

    if (documents) {
      userData.append('identification.image1', documents.files[0]);
      userData.append('identification.image2', documents.files[1]);
      userData.append('identification.serial', documents.serial);
      userData.append('identification.number', documents.number);
      userData.append('is_active', true);
    }

    return SendRequest('PATCH', `/v1/users/${userId}/`, userData, data => {
      return data || { error: true };
    });
  };

  return request;
};


