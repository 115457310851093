export default {
  namespaced: true,
  state: {},
  actions: {
    setInviteStatus({ rootState }, { id, status }) {
      return RequestManager.Invite.setInviteStatus(rootState.user.user.id, id, status);
    },
    getInviteInfo({ rootState}, inviteId) {
      return RequestManager.Invite.getInfo(rootState.user.user.id, inviteId);
    }
  }
};
