export default SendRequest => {
  const request = {};
  request.sendUser = ({ general, documents, company, coordinates }) => {
    const user = new FormData();

    if (general) {
      Object.entries(general).forEach(([k, v]) => {
        if (k === 'photo') {
          v instanceof File && user.append(k, v);
        } else if (v !== null && v !== undefined) {
          user.append(k, v);
        }
      });
    }

    if (documents) {
      documents.files[0].file && user.append('identification.image1', documents.files[0].file);
      documents.files[1].file && user.append('identification.image2', documents.files[1].file);
      user.append('identification.serial', documents.serial);
      user.append('identification.number', documents.number);
      user.append('is_active', true);
    }
    if (company) {
      user.append('company.legal_form.name', company.legal_form.name);
      user.append('company.legal_form.country', company.legal_form.country);
      user.append('company.docs.inn', company.docs.inn);
      user.append('company.docs.ogrn', company.docs.ogrn);
      company.files[0].file && user.append('company.docs.inn_img', company.files[0].file);
      company.files[1].file && user.append('company.docs.ogrn_img', company.files[1].file);
      // user.append('company.docs.is_verified', company.docs.is_verified);
      user.append('company.name', company.name);
      user.append('company.staff_num', company.staff_num);
      user.append('company.region', company.region);
    }
    if (coordinates) {
      user.append('coordinates.latitude', coordinates.latitude);
      user.append('coordinates.longitude', coordinates.longitude);
    }

    return SendRequest('PATCH', '/v1/users/me/', user, data => {
      return data || { error: true };
    });
  };

  //TODO удалить потом
  // request.uploadDocuments = ({files, serial, number}, {mobile, password, username}) => {
  //   const formData = new FormData();

  //   formData.append('identification[image1]', files[0]);
  //   formData.append('identification[image2]', files[1]);
  //   formData.append('identification[serial]', serial);
  //   formData.append('identification[number]', number);
  //   formData.append('mobile', mobile);
  //   formData.append('password', password);
  //   formData.append('username', username);

  //   return SendRequest('PUT', '/v1/users/me/', formData, data => {
  //     return data || { error: true };
  //   });
  // };

  request.sendFamily = (general, id) => {
    const user = new FormData();

    Object.entries(general).forEach(([k, v]) => {
      if (v) {
        user.append(k, v);
      }
    });

    return SendRequest('POST', `/v1/users/${id}/family/`, user, data => {
      return data || { error: true };
    });
  };

  request.checkMobile = (mobile) => {
    return SendRequest('POST', `/v1/users/check-mobile/`, { mobile }, data => {
      return data;
    });
  };

  return request;
};
