import Vue from 'vue';
import axios from 'axios';

import RequestManagerException from "@service/RequestManager/RequestManagerException";

import RequestTypePrepare from "@service/RequestManager/src/RequestPrepare/RequestTypePrepare";
import RequestUrlPrepare  from "@service/RequestManager/src/RequestPrepare/RequestUrlPrepare";
import RequestDataPrepare from "@service/RequestManager/src/RequestPrepare/RequestDataPrepare";

/**
 * @param type {String}
 * @param url {String}
 * @param baseUrl {String}
 * @param params {{get: Object, post:Object}}
 * @param userResponseDataPrepare {Function}
 * @return {Promise<unknown>}
 * @constructor
 */

const API_BASE_URL = process.env.VUE_APP_SERVER_API_URL;
const API_URL = API_BASE_URL ?? '$VUE_APP_SERVER_API_URL';

const SendRequest = (
  type,
  url,
  params,
  userResponseDataPrepare = (d) => d
) => {

  const _requestType  = RequestTypePrepare(type, url, params);
  const _requestUrl   = RequestUrlPrepare(type, url, params);
  const _requestData  = RequestDataPrepare(type, url, params);

  // TODO: use getApiResponsePrepare
  const apiResponsePrepare = (responseData) => {
    // TODO: fix
    if (responseData.success === false) {
      throw new RequestManagerException('BACKEND_ERROR', responseData.error, responseData);
    }
    return responseData;
  };

  type    = _requestType;
  url     = _requestUrl;
  params  = _requestData;

  const axiosObject = {
    method: type,
    url: API_URL + url,
    headers: {},
    // baseUrl: API_URL,
  };

  let token = localStorage.getItem('token');
  if (token) {
    axiosObject.headers['Authorization'] = `Token ${token}`;
  }

  if(params.get) {
    axiosObject.params = params.get;
  }
  if(params.post){
    axiosObject.data = params.post;
    if (params.post instanceof FormData) {
      axiosObject.headers['Content-Type'] = 'multipart/form-data';
    }
  }

  const axiosResponsePrepare = (response) => {
    if (!response) {
      throw new RequestManagerException('AXIOS_ERROR', '', response);
    }
    return response.data;
  };

  let request = axios(axiosObject)
    .then(axiosResponsePrepare)
    .then(apiResponsePrepare)
    .then(userResponseDataPrepare)
    .catch( (error) => {
      if ( error.isAxiosError ) {

        if (error?.response?.data?.non_field_errors) {
          const errorMessage = error?.response?.data?.non_field_errors[0];
          error = new RequestManagerException('NON_FIELD_ERROR', error.response.data.non_field_errors , {axiosErrorObject: error});
          Vue.prototype.$modal.show('ErrorMessage', { text: errorMessage });
        }

        if (error?.response?.status === 401) {
          window.VueApp.$store.dispatch('auth/logout');
          return;
        }

        if (error?.response?.data?.detail && error?.response?.status !== 401) {
          const errorMessage = error?.response?.data?.detail;
          error = new RequestManagerException('LOGIN_ERROR', error.response.data.detail , {axiosErrorObject: error});
          // FIXME: Временное решение для InfiniteLoader, пока не придумаю как сделать нормально
          errorMessage !== 'Invalid page.' && Vue.prototype.$modal.show('ErrorMessage', { text: errorMessage });
        }

        if (error && error.message) {
          if (error?.response?.data?.[0]?.mobile) {
            Vue.prototype.$modal.show('ErrorMessage', { text: error.response.data[0].mobile[0] });
          }
          error = new RequestManagerException('AXIOS_REQUEST_ERROR', error.message, {axiosErrorObject: error});
        }

        if (error && error.response && error.response.data && error.response.data.error) {
          error = new RequestManagerException('AXIOS_REQUEST_ERROR', error.response.data.error , {axiosErrorObject: error});
        }

      }
      else if ( !(error instanceof RequestManagerException) ) {
        error = new RequestManagerException('UNDEFINED_ERROR', '', error);
      }

      return new Promise((resolve, reject) => {
        reject(error);
      });
    });

  if(window.VueApp) {
    window.VueApp.$store.dispatch('addRequest', request);
  }
  return request;
};

export default SendRequest;
